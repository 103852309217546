/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "palsie/pals3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "palsie/pals3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(relativePath: { eq: "palsie/pals2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `M.Pals - Maak je muur behangklaar met een professioneel tintje.`,
  textWithLink: () => (
    <>
      <p>
        Behangklaar stucwerk aanbrengen zal nodig zijn als de wand vol met
        gaatjes en oneffenheden zitten. In dat geval is het nodig om de wanden
        behangklaar te stuken om mooie vlakke en egale wanden te krijgen.
      </p>
      <div className='hidden-sm hidden-md hidden-lg' data-sal='slide-top'>
        <a href='tel:0629575549'>
          <Button
            role='button'
            variant='contained'
            className='btn heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs' data-sal='slide-top'>
        <Button
          role='button'
          variant='contained'
          className='btn heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
    </>
  ),
};

const textBottom = {
  textWithLink: () => (
    <>
      <AniLink fade to='/wandafwerking'>
        <Button
          role='button'
          variant='contained'
          className='btn heading__button'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const BehangKlaar = ({ data, path, location }) => {
  const seo = {
    title: `M.Pals - Maak je huis behangklaar`,
    description: `Er kan niet behangt worden op een muur met gaatjes, laat je muur behangklaar maken door M.Pals Stukadoors.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'M.Pals Stukadoors',
    imgTitle: 'M.Pals Stukadoors',
    header: 'M.Pals - Behangklaar',
    inner:
      'Wanneer de stukadoor de wanden behangklaar zal stuken zal dat wel inhouden dat de wanden alleen geschikt zullen zijn om over heen te behangen en niet om over heen te schilderen of te sauzen. Het is niet altijd nodig om de wanden helemaal te stuken door de stukadoor, in sommige situaties kan er voor gekozen worden om de wanden te repareren. Dat kan dan beslist worden tijdens een bezichtiging van het project!',
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Maak je huis behangklaar`,
    pitchLine: `Er kan niet behangt worden op een muur met gaatjes, laat je muur behangklaar maken door M.Pals Stukadoors.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        textBottom={textBottom}
      />
    </>
  );
};

BehangKlaar.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default BehangKlaar;
